import useSWRImmutable from "swr/immutable";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function useSearchDevices() {
  const { data, error } = useSWRImmutable(`/api/cache/searchDevices`, fetcher);
  return {
    devices: data,
    isLoading: !error && !data,
    isError: error,
  };
}
