import { Fragment, useRef, useState, useEffect, useMemo } from "react";
import Link from "next/link";
import Image from "next/image";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import Fuse from "fuse.js";
import { trim, capitalize } from "lodash-es";
import { event } from "/lib/ga/gtag";
import useSearchDevices from "@hooks/data/useSearchDevices";
import { useRouter } from "next/router";

const repairTranslations = {
  nl: "herstellingen",
  en: "repairs",
  fr: "réparations",
};

const SearchModal = ({ dark }) => {
  const { devices, isLoading } = useSearchDevices();
  const [query, setQuery] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const searchInputRef = useRef(null);
  const router = useRouter();
  const { locale } = router;
  const repairTranslation = repairTranslations[locale];

  const fuse = useMemo(
    () =>
      new Fuse(devices, {
        isCaseSensitive: false,
        shouldSort: true,
        threshold: 0.4,
        keys: [
          "name",
          "slug",
          "main_model_number",
          "model_numbers",
          "brand.slug",
          "type.slug",
        ],
      }),
    [devices]
  );

  useEffect(() => {
    if (query.match(/[A-Z]{4}[0-9]{10}$/g) ? true : false) {
      setSearchItems([{ code: trim(query) }]);
    } else if (!isLoading && fuse) {
      setSearchItems(fuse.search(query).map((res) => res.item));
      if (searchItems.length > 5 && query.length > 3) {
        event({ action: "search", params: { search_term: query } });
      } else if (query !== "" && query.length > 4 && searchItems.length === 0) {
        //nothing found event
        event({ action: "search", params: { search_term: query, no_results: true } });
      }
    }
  }, [query, fuse]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <button
        onClick={() => {
          openModal();
        }}
        type="button"
        className={`flex-shrink-0 rounded-full p-1 ${dark
          ? "text-slate-300 hover:text-slate-200 "
          : "text-slate-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-ifixers focus:ring-offset-2 "
          }`}
      >
        <span className="sr-only">View notifications</span>
        <SearchIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <Transition.Root
        show={isOpen}
        as={Fragment}
        afterLeave={() => setQuery("")}
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-20 overflow-y-auto p-4 sm:p-6 md:p-20"
          onClose={closeModal}
          initialFocus={searchInputRef}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-slate-800 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Combobox
                as="div"
                className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                onChange={(url) => {
                  router.push(url);
                  closeModal();
                }}
              >
                <div className="relative">
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 font-medium text-slate-800 placeholder-gray-400 focus:ring-0 sm:text-base"
                    placeholder="Zoeken..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                {searchItems.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-96 scroll-py-3 overflow-y-auto p-3"
                  >
                    {searchItems.slice(0, 8).map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={`/${repairTranslation}/${item.type.slug}/${item.brand.slug}/${item.group?.slug}/${item.slug}`}
                        className={({ active }) =>
                          `${active ? "bg-slate-200" : ""
                          } flex w-full cursor-pointer select-none flex-row items-center justify-start space-x-3 rounded-lg border-2 border-transparent px-3 py-2 transition hover:bg-slate-200`
                        }
                      >
                        <div>
                          {item.image ? (
                            <div className="relative h-20 w-20 ">
                              <Image
                                src={item.image.formats.thumbnail.url}
                                alt={item.name}
                                layout="fill"
                                objectFit="contain"
                                objectPosition="left"
                              />
                            </div>
                          ) : (
                            <div className="relative h-20 w-20 ">
                              <Image
                                src="/images/phone-placeholder.png"
                                alt={item.name}
                                layout="fill"
                                objectFit="contain"
                                objectPosition="left"
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <div className="flex flex-col items-start justify-between">
                            <span className="text-sm font-semibold text-slate-600">
                              {capitalize(item.brand.slug)}
                            </span>
                          </div>
                          <div className="flex flex-col items-start justify-between">
                            <span className="text-lg font-semibold text-gray-700">
                              {item.name}
                            </span>
                          </div>
                          <div className="flex flex-col items-start justify-between">
                            <span className="text-sm font-semibold text-slate-600 text-left">
                              {item.model_numbers?.length > 1
                                ? item.model_numbers
                                  .slice(0, 4)
                                  .map(
                                    (number, i) =>
                                      number +
                                      (i <
                                        item.model_numbers.slice(0, 4).length -
                                        1
                                        ? ", "
                                        : "")
                                  )
                                : item.main_model_number}
                            </span>
                          </div>
                        </div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
                {query !== "" && searchItems.length === 0 && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">
                      No results found
                    </p>
                    <p className="mt-2 text-gray-500">
                      No components found for this search term. Please try
                      again.
                    </p>
                  </div>
                )}
              </Combobox>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SearchModal;
